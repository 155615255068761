import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useFillUserDataCountDownStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    background: theme.palette.primary.lighter,
    color: theme.palette.text.primary,
    padding: theme.spacing(3),
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    marginBottom: theme.spacing(2),
    marginTop: -theme.spacing(3),
    marginLeft: -theme.spacing(3),
    marginRight: -theme.spacing(3),

    [theme.breakpoints.up("md")]: {
      marginTop: -theme.spacing(8),
      marginLeft: -theme.spacing(8),
      marginRight: -theme.spacing(8),
    },

    [theme.breakpoints.up("lg")]: {
      marginTop: "unset",
      marginLeft: "unset",
      marginRight: "unset",
      position: "absolute",
      right: 0,
      top: 0,
      borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
    }
  },
  timerContainer:{
    display: "flex",
    alignItems:"center",
    justifyContent:"center",
  },
  timerBox:{
    marginTop: theme.spacing(2),
    border: `1px ${theme.palette.success.dark} solid`,
    borderRadius: "4px",
    padding: "2px",
    width: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  lowTime: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}))
