import i18next from "i18next"

import store from "../../../store"
import { checkFeatureAvailability } from "../../../store/clinic/clinicSettings.utils"
import { VoucherTypes } from "../voucherBox/VoucherBox.utils"

export const voucherCodeToBuyTranslation = (translationKey: string, voucherCode: string) => {
  return i18next.t(`finances:voucherCodeToBuy:${voucherCode.toLowerCase()}:${translationKey}`)
}

export const getVoucherDescriptionTranslationKeyByCode = (
  key: string,
  voucherCodeToBuy: string = VoucherTypes.TYPE_TELEMEDI_GO
) => {
  if (
    checkFeatureAvailability().isTelemediGoForSpecialistsEnabled
      && voucherCodeToBuy === VoucherTypes.TYPE_TELEMEDI_GO
  ) {
    return `specialists:${key}`
  }

  return key
}

export const canBuyVoucherCode = (): boolean => {
  const voucherCode = checkFeatureAvailability().voucherCodeToBuy
  const user = store.getState().user.userData

  if (voucherCode === VoucherTypes.TYPE_MEDI_GO && !user?.is_active_medi_go) {
    return true
  }
  
  if (voucherCode === VoucherTypes.TYPE_TELEMEDI_GO && !user?.is_active_telemedi_go) {
    return true
  }

  return false
}
