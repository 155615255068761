import React, {FC, useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import {useSelector} from "react-redux"
import {useHistory, useParams} from "react-router"
import {Box, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import {CancelTokenSource} from "axios"

import api from "../../api/api"
import {getConsultationConfig, getExamCartDetails} from "../../api/routes"
import useInterval from "../../hooks/useInterval"
import { useQueryParam } from "../../hooks/useSearchParams"
import {selectUser} from "../../store/user/user.selectors"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import {GetExamCartDetails} from "../../components/consultation/Consultation.types"
import {useSuccessfulPaymentPageStyles } from "../../components/successfulPayment/SuccessfulPayment.styles"

type ExamPaymentStatusPageParams = {
  examCartId: string;
}

const CHECK_PAYMENT_STATUS_DELAY = 5000
const CHECK_PAYMENT_STATUS_LIMIT = 20

const ExamPaymentStatusPage: FC = () => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const [checkLimitCount, setCheckLimitCount] = useState<number>(1)
  const userData = useSelector(selectUser)
  const {examCartId} = useParams<ExamPaymentStatusPageParams>()
  const classes = useSuccessfulPaymentPageStyles()
  const {push} = useHistory()
  const utmPageSource = useQueryParam("utm_page_source")

  const checkPaymentStatus = async () => {
    const examCartData = await getExamCartDetailsData()
    const paymentSuccess = examCartData?.is_paid == 1

    setCheckLimitCount(prev => prev + 1)

    if (paymentSuccess || checkLimitCount === CHECK_PAYMENT_STATUS_LIMIT) {
      goToPaymentSummaryPage(paymentSuccess || false)
    }
  }

  const {start, stop} = useInterval(checkPaymentStatus, CHECK_PAYMENT_STATUS_DELAY)

  const goToPaymentSummaryPage = (isPaid: boolean) => {
    push(
      isPaid
        ? `/${i18n.language}/exams-payment-successful`
        : `/${i18n.language}/exam-payment-declined`
    )
  }

  const getExamCartDetailsData = async (cancelToken?: CancelTokenSource["token"]) => {
    if (!userData) {
      return null
    }

    try {
      const {data} = await api.request<GetExamCartDetails>({
        ...getExamCartDetails(examCartId),
        cancelToken,
      })

      return data
    } catch (e) {
      if (api.isCancel(e)) return
    }
  }

  useEffect(() => {
    start()
    return (): void => {
      stop()
    }
  }, [])

  useEffect(() => {
    if (userData) {
      checkPaymentStatus()
    }
  }, [userData])

  return (
    <Box
      className={classes.pageSection}
      component="section"
    >
      <Box className={classes.content}>
        <Typography
          variant={isMdUp ? "h3" : "h5"}
          component="h2"
          className={classes.title}
        >
          { t("payments:checkingStatus") }
        </Typography>
        <Box>
          <LoaderBox />
        </Box>
      </Box>
    </Box>
  )
}

export default ExamPaymentStatusPage
