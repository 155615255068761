import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {NavLink} from "react-router-dom"
import {
  Box,
  ButtonBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,   Typography, } from "@material-ui/core"
import clsx from "clsx"

import {MAIN_ACCOUNT_MAGIC_LINK} from "../../../app.config"
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks"
import {clearSession} from "../../../store/session/session.slice"
import {selectDependentAccounts, selectUserFirstName, selectUserLastName} from "../../../store/user/user.selectors"
import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import CloseButton from "../../common/closeButton/CloseButton.component"
import LangDropdown from "../../common/langDropdown/LangDropdown.component"
import UserAvatarComponent from "../../user/userAvatar/UserAvatar.component"
import LogoutMenuItem from "../userMenuCommon/logoutMenuItem/LogoutMenuItem.component"
import {checkFeatureAvailability} from "../../../store/clinic/clinicSettings.utils"
import {RoutePath} from "../../../routes/Routes.types"
import {useUserMenuStyles} from "../userMenu/UserMenu.styles"
import { useUserMenuMobileStyles } from "./UserMenuMobile.styles"
// import {notificationsMock} from "../../notifications/Notifications.mock"
// import {NotificationItemStatusType} from "../../notifications/Notifications.types"

interface UserMenuMobileProps {
  disabled: boolean
}

const UserMenuMobile: FC<UserMenuMobileProps> = ({ disabled }) => {
  const {t, i18n} = useTranslation()
  const dispatch = useAppDispatch()
  const benefitSystemIntegrationEnabled = checkFeatureAvailability().enableBenefitSystemIntegration // todo change to general checkbox (hide finances in menu and finances section)
  const classes = useUserMenuMobileStyles()
  const menuClasses = useUserMenuStyles()
  const dependentAccountsEnabled = checkFeatureAvailability().dependentAccountsEnabled
  const hasDependentAccounts = useSelector(selectDependentAccounts)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  // const unreadNotificationsCount = notificationsMock.filter(item => item.status === NotificationItemStatusType.UNREAD).length

  const name = useAppSelector(selectUserFirstName)
  const surname = useAppSelector(selectUserLastName)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const logOut = () => {
    handleMenuClose()
    dispatch(clearSession())
  }

  return (
    <>
      <ButtonBase
        id="user-menu-mobile"
        aria-controls="menu-appbar"
        aria-label={t("user:userSettings")}
        aria-haspopup="true"
        disabled={disabled}
        onClick={handleMenuOpen}
      >
        {/* HIDDEN DUE TO LACK OF FUNCTIONALITY
        {/*<Badge*/}
        {/*  classes={{*/}
        {/*    badge: classes.badge*/}
        {/*  }}*/}
        {/*  badgeContent={unreadNotificationsCount}*/}
        {/*  invisible={!unreadNotificationsCount}*/}
        {/*  color="error"*/}
        {/*>*/}
        <UserAvatarComponent
          name={name}
          surname={surname}
          size={5.5}
          fontVariant="h4"
        />
        {/*</Badge>*/}
      </ButtonBase>

      {/* mobile user-menu */}
      <Menu
        aria-labelledby="user-menu-mobile"
        anchorEl={anchorEl}
        autoFocus={false}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleMenuClose}
        open={open}
        classes={{
          paper: classes.menuPaper,
        }}
      >
        <CloseButton
          topCSS={2}
          rightCSS={0.5}
          onClick={handleMenuClose}
        />
        <Box
          pt={4}
          pb={1}
          pl={3}
          mr={7}
        >
          <Typography
            variant="h5"
            component="p"
          >
            {name} {surname}
          </Typography>
        </Box>

        <Box mt={2}>
          {/* HIDDEN SECTION DUE TO LACK OF FUNCTIONALITY
          <MenuItem
            className={clsx(classes.listItemRoot, menuClasses.customHover)}
            component={NavLink}
            to={`/${i18n.language}/notifications`}
            onClick={handleMenuClose}
          >

            <ListItemIcon classes={{root: classes.listItemIconRoot}}>
              <Badge
                classes={{
                  badge: classes.notificationsBadge,
                }}
                badgeContent={unreadNotificationsCount}
                invisible={!unreadNotificationsCount}
                color="error"
              >
                <Typography variant="h3" component="span" className="icon-bell"/>
              </Badge>
            </ListItemIcon>
            <ListItemText
              classes={{root: classes.listItemTextRoot}}
              disableTypography
            >
              {t("user:notifications")}
            </ListItemText>
          </MenuItem>
          */}

          <MenuItem
            className={clsx(classes.listItemRoot, menuClasses.customHover)}
            component={NavLink}
            to={`/${i18n.language}/user-profile#myProfile`}
            onClick={handleMenuClose}
          >
            <ListItemIcon classes={{root: classes.listItemIconRoot}}>
              <IconByIntegrationType iconName={"icon-user"} />
            </ListItemIcon>
            <ListItemText
              classes={{root: classes.listItemTextRoot}}
              disableTypography
            >
              {t("user:myData")}
            </ListItemText>
          </MenuItem>

          {/* HIDDEN SECTION DUE TO LACK OF FUNCTIONALITY

          <ListItem
            className={clsx(classes.listItemRoot, menuClasses.customHover)}
            onClick={handleMenuClose}
          >
            <ListItemIcon classes={{root: classes.listItemIconRoot}}>
              <span className="icon-archive"/>
            </ListItemIcon>
            <ListItemText
              classes={{root: classes.listItemTextRoot}}
              disableTypography
            >
              {t("user:myHealth")}
            </ListItemText>
          </ListItem>

          E/O HIDDEN SECTION DUE TO LACK OF FUNCTIONALITY
          */}

          {!benefitSystemIntegrationEnabled && (
            <MenuItem
              className={clsx(classes.listItemRoot, menuClasses.customHover)}
              component={NavLink}
              to={`/${i18n.language}/user-profile#finances`}
              onClick={handleMenuClose}
            >
              <ListItemIcon classes={{root: classes.listItemIconRoot}}>
                <IconByIntegrationType iconName={"icon-umbrella"} />
              </ListItemIcon>
              <ListItemText
                classes={{root: classes.listItemTextRoot}}
                disableTypography
              >
                {t("finances:finances")}
              </ListItemText>
            </MenuItem>
          )}

          <LangDropdown/>

          {/* HIDDEN SECTION DUE TO LACK OF CHILD-PARENT ACCOUNT FUNCTIONALITY

          {childPatients.map(child => (
            <div key={child.userId}>
              <Divider classes={{root: menuClasses.dividerRoot}}/>
              <ListItem
                className={clsx(classes.childListItemRoot, menuClasses.customHover)}
                onClick={handleMenuClose}
              >
                <ListItemAvatar classes={{root: classes.listItemAvatarRoot}}>
                  <UserAvatarComponent
                    name={child.name}
                    surname={child.surname}
                  />
                </ListItemAvatar>
                <ListItemText disableTypography>
                  <Typography variant="h4" component="p" noWrap>{child.name} {child.surname}</Typography>
                  <Typography variant="body1" color="textSecondary">{t("user:showChildsAccount")}</Typography>
                </ListItemText>
              </ListItem>
            </div>
          ))}

          E/O HIDDEN SECTION DUE TO LACK OF CHILD-PARENT ACCOUNT FUNCTIONALITY
          */}
        </Box>

        {dependentAccountsEnabled
            && (hasDependentAccounts || localStorage.getItem(MAIN_ACCOUNT_MAGIC_LINK))
            &&
            <MenuItem
              className={clsx(classes.listItemRoot, classes.customHover)}
              component={NavLink}
              to={`/${i18n.language}${RoutePath.SWITCH_ACCOUNT}`}
              onClick={handleMenuClose}
            >
              <ListItemIcon classes={{root: classes.listItemIconRoot}}>
                <IconByIntegrationType iconName={"icon-group"} />
              </ListItemIcon>
              <ListItemText
                classes={{root: classes.listItemTextRoot}}
                disableTypography
              >
                {t("user:switchAccount")}
              </ListItemText>
            </MenuItem>
        }

        <MenuItem
          className={clsx(classes.listItemRoot, menuClasses.customHover)}
          onClick={logOut}
        >
          <LogoutMenuItem
            classes={{
              iconRoot: clsx(classes.listItemIconRoot, classes.listItemLogout),
              textRoot: classes.listItemTextRoot,
            }}
            logoutLabel={t("user:logOut")}
          />
        </MenuItem>
      </Menu>
      {/* e/o mobile user-menu */}
    </>
  )
}

export default UserMenuMobile
