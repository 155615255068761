import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import i18next from "i18next"

import { selectUser } from "../../store/user/user.selectors"
import { HealthPreventionProgramsType } from "../../components/healthPrevention/healthPrevention.types"
import MentalHealthDashboard from "./components/MentalHealthDashboard/MentalHealthDashboard.component"
import { checkFeatureAvailability } from "../../store/clinic/clinicSettings.utils"

const MentalHealthPageRouter: FC = () => {
  const { i18n } = useTranslation()
  const { push } = useHistory()
  const userData = useSelector(selectUser)

  const showMentalHealthTab = checkFeatureAvailability().enabledMentalHealth 
      && i18n.language === "pl" 
      && userData?.healthPreventionPrograms
      && userData?.healthPreventionPrograms?.includes(HealthPreventionProgramsType.MENTAL_HEALTH)

  if (!showMentalHealthTab) {
    push(`/${i18next.language}`)

    return null
  }
  
  return <MentalHealthDashboard/>
}

export default MentalHealthPageRouter
