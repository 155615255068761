import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {NavLink} from "react-router-dom"
import { ButtonBase, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@material-ui/core"
import clsx from "clsx"

import {MAIN_ACCOUNT_MAGIC_LINK} from "../../../app.config"
import {selectDependentAccounts, selectUserFirstName, selectUserLastName} from "../../../store/user/user.selectors"
import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import LangDropdown from "../../common/langDropdown/LangDropdown.component"
import UserAvatarComponent from "../../user/userAvatar/UserAvatar.component"
import LogoutMenuItem from "../userMenuCommon/logoutMenuItem/LogoutMenuItem.component"
import {checkFeatureAvailability} from "../../../store/clinic/clinicSettings.utils"
import {RoutePath} from "../../../routes/Routes.types"
import { useUserMenuStyles } from "./UserMenu.styles"

import { useUserMenu } from "./UserMenu.hook"

interface UserMenuProps {
  disabled: boolean
}

const UserMenu: FC<UserMenuProps> = ({ disabled }) => {
  const {t, i18n} = useTranslation()
  const benefitSystemIntegrationEnabled = checkFeatureAvailability().enableBenefitSystemIntegration // todo change to general checkbox (hide finances in menu and finances section)
  const dependentAccountsEnabled = checkFeatureAvailability().dependentAccountsEnabled
  const classes = useUserMenuStyles()

  const userFirstName = useSelector(selectUserFirstName)
  const userLastName = useSelector(selectUserLastName)
  const hasDependentAccounts = useSelector(selectDependentAccounts)

  const {
    anchorEl,
    open,
    handleMenu,
    handleClose,
    logOut,
    handleGoToMyProfilePage,
    handleGoToFinancesPage,
    handleGoToSwitchProfilePage
  } = useUserMenu()

  const subheader = (
    <div>
      <Typography
        variant="h4"
        component="p"
        className={classes.listSubheader}
      >
        {userFirstName} {userLastName}
      </Typography>
    </div>
  )

  return (
    <>
      <ButtonBase
        aria-controls="menu-appbar"
        aria-label={t("user:userSettings")}
        aria-haspopup="true"
        disabled={disabled}
        onClick={handleMenu}
      >
        <UserAvatarComponent
          name={userFirstName}
          surname={userLastName}
        />
      </ButtonBase>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoFocus={false}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: classes.menuPaper,
          list: classes.listRoot,
        }}
        MenuListProps={{subheader}}
      >
        <MenuItem
          className={clsx(classes.listItemRoot, classes.customHover)}
          component={NavLink}
          to={`/${i18n.language}/user-profile#myProfile`}
          onClick={handleGoToMyProfilePage}
        >
          <ListItemIcon classes={{root: classes.listItemIconRoot}}>
            <IconByIntegrationType iconName={"icon-user"} />
          </ListItemIcon>
          <ListItemText
            classes={{root: classes.listItemTextRoot}}
            disableTypography
          >
            {t("user:myData")}
          </ListItemText>
        </MenuItem>

        {/* HIDDEN SECTION DUE TO LACK OF FUNCTIONALITY

        <MenuItem
          className={clsx(classes.listItemRoot, classes.customHover)}
          component={NavLink}
          to={`/${i18n.language}/user-profile#myHealth`}
          onClick={handleClose}
        >
          <ListItemIcon classes={{root: classes.listItemIconRoot}}>
            <span className="icon-archive"/>
          </ListItemIcon>
          <ListItemText
            classes={{root: classes.listItemTextRoot}}
            disableTypography
          >
            {t("user:myHealth")}
          </ListItemText>
        </MenuItem>

        E/O HIDDEN SECTION DUE TO LACK OF FUNCTIONALITY
        */}

        {!benefitSystemIntegrationEnabled && (
          <MenuItem
            className={clsx(classes.listItemRoot, classes.customHover)}
            component={NavLink}
            to={`/${i18n.language}/user-profile#finances`}
            onClick={handleGoToFinancesPage}
          >
            <ListItemIcon classes={{root: classes.listItemIconRoot}}>
              <IconByIntegrationType iconName={"icon-umbrella"} />
            </ListItemIcon>
            <ListItemText
              classes={{root: classes.listItemTextRoot}}
              disableTypography
            >
              {t("finances:finances")}
            </ListItemText>
          </MenuItem> 
        )}

        <LangDropdown/>

        {dependentAccountsEnabled
            && (hasDependentAccounts || localStorage.getItem(MAIN_ACCOUNT_MAGIC_LINK))
            &&
            <MenuItem
              className={clsx(classes.listItemRoot, classes.customHover)}
              component={NavLink}
              to={`/${i18n.language}${RoutePath.SWITCH_ACCOUNT}`}
              onClick={handleGoToSwitchProfilePage}
            >
              <ListItemIcon classes={{root: classes.listItemIconRoot}}>
                <IconByIntegrationType iconName={"icon-group"} />
              </ListItemIcon>
              <ListItemText
                classes={{root: classes.listItemTextRoot}}
                disableTypography
              >
                {t("user:switchAccount")}
              </ListItemText>
            </MenuItem>
        }

        <MenuItem
          className={clsx(classes.listItemRoot, classes.customHover)}
          onClick={logOut}
        >
          <LogoutMenuItem
            classes={{
              iconRoot: classes.listItemIconRoot,
              textRoot: classes.listItemTextRoot,
            }}
            logoutLabel={t("user:logOut")}
          />
        </MenuItem>

        {/* CHILD ACCOUNTS HIDDEN DUE TO LACK OF FUNCTIONALITY

        {childPatients.map(child => (
          <div
            key={child.userId}
            className={classes.childListItemWrapper}
          >
            <Divider classes={{root: classes.dividerRoot}}/>
            <MenuItem
              className={clsx(classes.childListItemRoot, classes.customHover)}
              component={NavLink}
              to={`/${i18n.language}/user-profile#myChildren`}
              onClick={handleClose}
            >
              <ListItemAvatar classes={{root: classes.listItemAvatarRoot}}>
                <UserAvatarComponent
                  name={child.name}
                  surname={child.surname}
                />
              </ListItemAvatar>
              <ListItemText disableTypography>
                <Typography variant="h4" component="p" noWrap>{child.name} {child.surname}</Typography>
                <Typography variant="body1" color="textSecondary">{t("user:showChildsAccount")}</Typography>
              </ListItemText>
            </MenuItem>
          </div>
        ))}

        e/o CHILD ACCOUNTS HIDDEN DUE TO LACK OF FUNCTIONALITY */}
      </Menu>
    </>
  )
}

export default UserMenu
