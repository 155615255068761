import React, {FC, useState} from "react"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {useHistory} from "react-router"
import {Box, Button, Grid, Hidden, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import clsx from "clsx"
import {parseISO} from "date-fns"

import {
  internistSpecializationId
} from "../../../app.config"
import { ReactComponent as CalendarAddIcon } from "../../../assets/svg/calendar-add.svg"
import useGoToConsultationPage from "../../../hooks/useGoToConsultationPage"
import { analytics, LogEventType } from "../../../services/analytics"
import store from "../../../store"
import {selectClinicSettings} from "../../../store/clinic/clinicSettings.selectors"
import {selectUserPozDeclarationActiveStatus} from "../../../store/user/user.selectors"
import ImageByIntegrationType, {DOCTOR_DETAILS} from "../../imageByIntegrationType/ImageByIntegrationType"
import useImageByIntegrationType from "../../imageByIntegrationType/useImageByIntegrationType"
import ButtonTextUnderlined from "../../common/buttonTextUnderlined/ButtonTextUnderlined.component"
import LangFlag from "../../common/langFlag/LangFlag.component"
import TextLabel from "../../common/textLabel/TextLabel.component"
import ConsultationTypeIcon from "../../consultation/consultationTypeIcon/ConsultationTypeIcon.component"
import ConsultationTypeText from "../../consultation/consultationTypeText/ConsultationTypeText.component"
import PrescriptionRejectedPopup from "../../consultation/prescriptionRejectedPopup/PrescriptionRejectedPopup.component"
import SickLeaveRejectedPopup from "../../consultation/sickLeaveRejectedPopup/SickLeaveRejectedPopup.component"
import AvatarSection from "../../services/comingServiceSections/avatarSection/AvatarSection.component"
import {checkFeatureAvailability} from "../../../store/clinic/clinicSettings.utils"
import { getLangFlagImgTitle } from "../../common/langFlag/LangFlag.utils"
import {
  getAvatarUrl,
  getConsultationLabelTag,
  getSickLeaveEmploymentTypeLabel,
  getStationaryVisitAddress,
  isStationaryVisitConsultation,
} from "../../consultation/Consultation.utils"
import {
  ConsultationStatusNumber,
  ConsultationType,
  FINISHED_WITH_SUCCESS_CONSULTATION_STATUS_NUMBERS,
  OrderedSickLeaveDetails,
  StationaryVisitAddress} from "../../consultation/Consultation.types"
import {DoctorImageType, DoctorSpecializationModel} from "../Doctor.types"
import {useCommonStyles} from "../../services/comingServiceSections/common/Common.styles"
import {useDoctorDetailsStyles} from "./DoctorDetails.styles"

interface DoctorDetailsProps {
  consultationId: string;
  consultationStartISODate: string | null;
  consultationType: ConsultationType;
  doctorImage: DoctorImageType;
  doctorSpecialization: DoctorSpecializationModel;
  doctorTitle: string;
  consultationStatusNumber: ConsultationStatusNumber;
  maxSectionHeight: number;
  consultationLanguage: string;
  doctorId?: string;
  stationaryVisitAddress?: StationaryVisitAddress;
  isInterestedInPoz?: boolean;
  isPrescriptionOnly?: boolean;
  isSickLeave?: boolean;
  orderedSickLeave?: OrderedSickLeaveDetails;
  isPaid?: boolean;
}

const ADDITIONAL_BUTTON_HEIGHT = 50

const DoctorDetails: FC<DoctorDetailsProps> = (
  {
    consultationId,
    consultationStartISODate,
    consultationType,
    doctorImage ,
    doctorSpecialization,
    doctorTitle,
    doctorId,
    consultationStatusNumber,
    maxSectionHeight,
    consultationLanguage,
    stationaryVisitAddress,
    isInterestedInPoz,
    isPrescriptionOnly,
    isSickLeave,
    orderedSickLeave,
    isPaid
  }
) => {
  const {frontendSettings: {style: {mainColor}}, hasBookConsultationAbility} = useSelector(selectClinicSettings)
  const classes = useDoctorDetailsStyles({customConsultationTypeColor: mainColor})
  const commonClasses = useCommonStyles()
  const { t, i18n } = useTranslation()
  const isPozDeclarationActive = useSelector(selectUserPozDeclarationActiveStatus)
  const {imageSrcState} = useImageByIntegrationType("stationaryVisit.png")

  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const {push} = useHistory()
  const [showRejectedPrescriptionPopup, setShowRejectedPrescriptionPopup] = useState<boolean>(false)
  const [showRejectedSickLeavePopup, setShowRejectedSickLeavePopup] = useState<boolean>(false)
  const standardConsultation = !isPrescriptionOnly && !isSickLeave
  const isSuccessFinishedConsultation = FINISHED_WITH_SUCCESS_CONSULTATION_STATUS_NUMBERS.includes(consultationStatusNumber)
  const maxSectionTargetHeight = (standardConsultation && isSuccessFinishedConsultation && isXs) ? maxSectionHeight + ADDITIONAL_BUTTON_HEIGHT : maxSectionHeight
  const controlVisitAvailable = !isPozDeclarationActive
  const isStationaryVisitPatientCheckboxEnabled = store.getState().clinic?.clinicSettings?.stationaryVisitPatient
  const hideConsultationBooking = checkFeatureAvailability().hideConsultationBooking
  const isDesktop = window.innerWidth > 959

  const getConsultationType = (consultationType: number) => {
    switch(consultationType) {
      case ConsultationType.PRESCRIPTION_ONLY:
        return "prescription"
      case ConsultationType.SICK_LEAVE:
        return "sickleave"
    }
  }

  const { goToMakeConsultationPage } = isSickLeave || isPrescriptionOnly
    ? useGoToConsultationPage({
      visitDatePicker: getConsultationType(consultationType),
      controlVisit: true,
    }) 
    : useGoToConsultationPage(
      null,
      `?consultationLanguage=${consultationLanguage}&doctorId=${doctorId}&specializationId=${doctorSpecialization?.id}&consultationChannel=${consultationType}`)

  const getConsultationDetailsTitle = (consultationType: number): string | undefined | null => {
    if (isStationaryVisitConsultation(consultationType, stationaryVisitAddress)) {
      return consultationStartISODate && t("dates:consultationDayWithFullYearDateAndHour", { date: parseISO(consultationStartISODate) })
    }

    switch(consultationType) {
      case ConsultationType.PRESCRIPTION_ONLY:
        return consultationStartISODate && t("dates:consultationDayWithDate", { date: parseISO(consultationStartISODate) })
      case ConsultationType.SICK_LEAVE:
        return orderedSickLeave ? t("consultation:sickLeavePeriod", { startDate: orderedSickLeave?.start_at, endDate: orderedSickLeave?.end_at }) : ""
      default:
        return consultationStartISODate ? t("dates:consultationFullDate", { date: parseISO(consultationStartISODate) }) : null
    }
  }

  const isStationaryVisitType = isStationaryVisitConsultation(consultationType, stationaryVisitAddress)
  const consultationStatusLabel = getConsultationLabelTag(consultationStatusNumber, consultationType, isStationaryVisitType, isPaid)
  const stationaryAddress = isStationaryVisitType && stationaryVisitAddress
    ? (
      <Box
        ml={isMdUp ? 4.5 : 0 }
        mt={1}
        mb={isMdUp ? 0 : 1}
        className={commonClasses.stationaryVisitAddressLabel}
      >
        <Typography 
          variant={isMdUp? "body2" : "body1"}
          className={commonClasses.stationaryVisitAddress}
        >
          {getStationaryVisitAddress(stationaryVisitAddress)}
        </Typography>
      </Box>
    )
    : null

  const goToConsultationPage = () => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_CONSULTATION_GO_TO_CHAT_LINK_CLICK)
    push(`/${i18n.language}/consultation/${consultationId}`)
  }

  const handleBookControlVisit = () => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_CONSULTATION_MAKE_APPOINTMENT_AGAIN_BUTTON_CLICK)
    goToMakeConsultationPage()
  }

  /* HIDDEN DUE TO NO FUNCTIONALIY
  const openMakeConsultationModal = () => {
    store.dispatch(setMakeConsultationWidgetSpecializationID(doctorSpecialization.id))
    store.dispatch(setOpenMakeConsultationWidgetModal(true))
  }
*/

  const consultationActionButtonSection = (isPrescriptionOnly || isSickLeave) && consultationStatusNumber === ConsultationStatusNumber.FINISHED_NO_ANSWER_OTHER ? (
    <Button
      variant="outlined"
      color={"primary"}
      onClick={() => isPrescriptionOnly ? setShowRejectedPrescriptionPopup(true) : setShowRejectedSickLeavePopup(true)}
    >
      { t("consultation:details") }
    </Button>
  ) : null

  const consultationDateSection = (
    <Box mb={!isMdUp ? 2 : 0}>
      <Typography
        variant={isMdUp ? "h5" : "body1"}
        component="time"
        className={classes.consultationDate}
      >
        { getConsultationDetailsTitle(consultationType) }
      </Typography>
    </Box>
  )

  const consultationTypeSection = !isPrescriptionOnly ? (
    <Box className={classes.consultationTypeSection}>
      <ConsultationTypeIcon
        consultationType={consultationType}
        consultationFinished={true}
        variant={isMdUp ? "h4" : "body1"}
        className={classes.consultationTypeImg}
        color="textPrimary"
        isStationaryVisit={isStationaryVisitType}
      />

      <ConsultationTypeText
        consultationType={consultationType}
        variant={isMdUp ? "h5" : "body1"}
        color={"textPrimary"}
        isStationaryVisit={isStationaryVisitType}
      />
    </Box>
  ) : null

  const controlVisitSection = (
    (
      controlVisitAvailable ||
      (
        hasBookConsultationAbility &&
        consultationType !== ConsultationType.SICK_LEAVE &&
        consultationType !== ConsultationType.PRESCRIPTION_ONLY &&
        internistSpecializationId !== doctorSpecialization?.id
      )
    ) && (
      // Remove buttons if it has the following types
      consultationType !== ConsultationType.TYPE_STATIONARY_NON_INTEGRATED
    ) && !hideConsultationBooking
    && !(
      checkFeatureAvailability().enabledNewPrescriptionConsbookWidget
        && isPrescriptionOnly
    )
  ) && (
    <Box
      alignSelf={isMdUp ? "flex-end" : "center" }
    >
      {
        isStationaryVisitPatientCheckboxEnabled && !isStationaryVisitConsultation(consultationType, stationaryVisitAddress) && (
          <Button
            variant="contained"
            color="primary"
            type="button"
            className={clsx(classes.button, classes.newVisitButton)}
            onClick={handleBookControlVisit}
            startIcon={
              <ImageByIntegrationType
                imageSrc={<CalendarAddIcon/>}
                imagePath={"calendar-add.svg"}
                step={DOCTOR_DETAILS}
                returnSvg={true}
              />
            }
          >
            {t("consultation:controlVisit")}
          </Button>
        )
      }
    </Box>
  )

  const buttonsSection =
  (
    // Remove buttons if it has the following types
    consultationType !== ConsultationType.TYPE_STATIONARY_NON_INTEGRATED
  ) && (
    <Box className={classes.buttonsSection}
      display="flex"
      justifyContent="flex-end"
      flexDirection={isMdUp ? "column" : "column-reverse"}
      alignSelf={isMdUp ? "flex-end" : "flex-start" }
      width="100%"
    >
      {/* HIDDEN DUE TO NO FUNCTIONALIY
      <ButtonTextUnderlined
        color={"primary"}
        className={classes.button}
        onClick={openMakeConsultationModal}
      >
        { t("makeConsultationAgain") }
      </ButtonTextUnderlined>
      */}
      <Box
        alignSelf={isMdUp ? "flex-end" : "flex-start" }
      >
        <Box className={isSickLeave || isPrescriptionOnly ? clsx(classes.controlVisitSection, classes.controlVisitSectionBottom) : classes.controlVisitSection}>
          {isDesktop && controlVisitSection}
        </Box>
        <ButtonTextUnderlined
          color={"primary"}
          className={classes.button}
          onClick={goToConsultationPage}
        >
          { t(isStationaryVisitType
            ? "consultation:showCompletedStationaryConsultationDetails"
            : "consultation:showConsultationChat"
          )}
        </ButtonTextUnderlined>
      </Box>
    </Box>
  )

  const rejectedConsultationStatusLabel = (
    <Box ml="auto" my={!isMdUp ? 2 : 0}>
      { consultationStatusLabel && (
        <TextLabel
          text={t(consultationStatusLabel)}
          textOverflow
          bgcolor={theme.palette.grey["400"]}
        />
      )
      }
    </Box>
  )

  return (
    <Box
      className={classes.root}
      style={{ maxHeight: `${maxSectionTargetHeight}px` }}
    >
      <Box className={classes.contentWrapper} mb={(!isSmUp && isPrescriptionOnly && consultationActionButtonSection) ? 4 : 0}>
        { !isMdUp && (
          <>
            <Box
              display="flex"
              alignItems="baseline"
              align-items="flex-start"
              justifyContent="space-between"
              width="100%"
            >
              { consultationDateSection }

              { consultationTypeSection }
            </Box>
          </>
        )}

        <Grid
          container
          spacing={0}
          justifyContent="space-between"
        >
          <Grid
            item
            sm={6}
            md={4}
          >
            <Box
              display="flex"
              flexDirection="row"
            >
              <AvatarSection
                src={getAvatarUrl(doctorImage?.src, consultationType, stationaryVisitAddress, doctorId, imageSrcState)}
                alt={doctorImage.alt || ""}
                className={
                  isStationaryVisitType && !doctorId ? commonClasses.hospitalImage : null
                }
              />
              <Box className={classes.doctorDataSection}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  height="100%"
                  width="100%"
                  ml={2}
                  mt={2}
                >
                  <Box mr={2}>
                    <Box mb={1}>
                      <TextLabel
                        text={isPrescriptionOnly ? t("consultation:prescription") : doctorSpecialization.name}
                      />
                    </Box>

                    {
                      (!isSickLeave) && (
                        <Box mb={3} className={classes.doctorTitle}>
                          <Typography
                            variant={isXs ? "subtitle1" : "h5"}
                          >
                            { doctorTitle }
                          </Typography>
                          {
                            consultationLanguage && (
                              <LangFlag
                                language={consultationLanguage}
                                title={getLangFlagImgTitle(consultationLanguage)}
                                label={getLangFlagImgTitle(consultationLanguage)}
                                className={commonClasses.langImg}
                              />
                            )
                          }
                        </Box>
                      )
                    }
                    {
                      isSickLeave && (
                        <Box
                          className={commonClasses.detailsSectionContainer}
                        >
                          <Typography
                            variant="h5"
                            className={classes?.textWhite}
                            title={t("consultation:employmentTypeLabel") || ""}
                          >
                            <Box>
                              {t("consultation:employmentTypeLabel")}
                            </Box>
                            <Typography
                              className={commonClasses.detailsSectionContent}
                            >
                              {getSickLeaveEmploymentTypeLabel(orderedSickLeave?.employment_type)}
                            </Typography>
                          </Typography>
                        </Box>
                      )
                    }
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            sm={6}
            md={4}
          >
            { isMdUp && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="baseline"
                justifyContent="flex-start"
                whiteSpace="nowrap"
                mt={isPrescriptionOnly ? 2 : 4.5}
              >
                { consultationDateSection }

                { consultationTypeSection }

                { stationaryAddress }
              </Box>
            )}
          </Grid>
          <Grid
            item
            md={2}
          >
            <Box
              display="flex"
              height="100%"
              justifyContent="flex-end"
              mt={(!isMdUp && isPrescriptionOnly) ? 3 : 0}
            >
              <Hidden only={"xs"}>
                <Box
                  display="flex"
                  flexDirection={(isPrescriptionOnly || isSickLeave) ? "column" : "row"}
                  justifyContent="space-between"
                >
                  { isSuccessFinishedConsultation
                    ? buttonsSection
                    : rejectedConsultationStatusLabel
                  }

                  {consultationActionButtonSection}
                </Box>
              </Hidden>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Hidden smUp>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignSelf={isMdUp ? "initial" : "flex-start"}
        >
          { isSuccessFinishedConsultation
            ? buttonsSection
            : rejectedConsultationStatusLabel
          }

          {consultationActionButtonSection}
        </Box>
      </Hidden>
      {isPrescriptionOnly && !isSuccessFinishedConsultation && (
        <PrescriptionRejectedPopup
          open={showRejectedPrescriptionPopup}
          onClose={() => setShowRejectedPrescriptionPopup(false)}
        />
      )}
      {isSickLeave && !isSuccessFinishedConsultation && (
        <SickLeaveRejectedPopup
          open={showRejectedSickLeavePopup}
          onClose={() => setShowRejectedSickLeavePopup(false)}
        />
      )}
    </Box>
  )
}

export default DoctorDetails
