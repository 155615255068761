import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import i18next from "i18next"
import {Reducer} from "redux"

import {internistSpecializationId, prescriptionExtensionSpecializationId, prescriptionSpecializationId, sickLeaveSpecializationId, ssoMiddlewareHost} from "../../app.config"
import {
  ConsultationChannelTypes,
  ConsultationWidgetDisplayMode,
  ConsultationWidgetVisitDatePicker,
  MakeConsultationWidgetDataConfig,
  MakeConsultationWidgetDataState,
} from "./makeConsultationWidget.types"

const initialState: MakeConsultationWidgetDataState = {
  isModalOpen: false,
  loading: true,
  rendered: false,
  config: {
    targetElementID: "telemedico-widget",
    displayMode: ConsultationWidgetDisplayMode.REGULAR_DISPLAY_MODE,
    apiHost: "",
    logoImage: "",
    splashScreen: false,
    splashScreenBackgroundImage: "",
    visitDatePicker: ConsultationWidgetVisitDatePicker.CONSBOOK_VISIT_DATA_PICKER,
    fullWidthLayout: true,
    formDescription: "",
    specializationID: internistSpecializationId,
    quickPaidRegisterPath: true,
    defaultStrategy: "loggedUserStrategy",
    showUserSessionStatus: false,
    embeddedInDashboard: true,
    additionalPaymentLinkRedirectURL: true,
    dashboardHost: window.location.origin,
    language: i18next.language,
    showPaidVisitsOnly: false,
    showPrices: false,
    instantChatEnabled: false,
    smallSlotsEnabled: false,
    defaultChannel: ConsultationChannelTypes.PHONE,
    fusionAuthEnabled: true,
    fusionAuthHost: ssoMiddlewareHost,
    themeColors: [],
    clinicId: "",
    medicalDrugLimit: 1,
    medicalDrugsTotalLimit: 1,
    prescriptionSpecializationId,
    prescriptionExtensionSpecializationId,
    enableCheckMedicineExposed: false,
    pozGlobalMedicalDrugLimit: 1,
    pozGlobalMedicalDrugsTotalLimit: 1,
    otherMessagesForB2bClinicEnabled: false,
    sickLeaveSpecializationId,
    telemediSubscriptionEnabled: false,
    checkConsultationReasonAvailability: false,
    pushMethod: null,
    defaultDoctorId: "",
    detailedSource: `${window.location.hostname}${window.location.pathname}`.slice(-10), // https://github.com/telemedico/consbook-widget/blob/production/README.md#konfiguracja [detailedSource]
    openReplayTracker: null,
    controlVisit: false,
    showPhoneNumberOnCompleteDataView: false,
    hideChangePassword: false,
    enabledS7HealthIntegration: false,
    enabledAllianzEwkaIntegration: false,
    enabledAllianzOnyxIntegration: false,
    isNewPrescriptionConsbookWidget: false
  },
}

const makeConsultationWidgetSlice = createSlice({
  name: "makeConsultationWidget",
  initialState,
  reducers: {
    setMakeConsultationWidgetConfig: (state: MakeConsultationWidgetDataState, action: PayloadAction<Partial<MakeConsultationWidgetDataConfig>>) => {
      state.config = {
        ...state.config,
        ...action.payload
      }
    },
    clearMakeConsultationConfig: (state: MakeConsultationWidgetDataState) => {
      state.config = initialState.config
    }
  }
})

export const {
  setMakeConsultationWidgetConfig,
  clearMakeConsultationConfig,
} = makeConsultationWidgetSlice.actions

export default makeConsultationWidgetSlice.reducer as Reducer<typeof initialState>
