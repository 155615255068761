import React, {FC, useEffect, useRef, useState} from "react"

export interface TimerProps{
  endTime: Date;
  onTimeEnd?: ()=>void;
  onTimeLow?: ()=>void;
  lowTimeThreshold?: number; //in ms, defaults to DEFAULT_LOW_TIME_THRESHOLD
}

const UPDATE_INTERVAL = 500 //in ms
const DEFAULT_LOW_TIME_THRESHOLD = 30 * 1000 //in ms

const CountDown: FC<TimerProps> = ({endTime, onTimeEnd,onTimeLow, lowTimeThreshold = DEFAULT_LOW_TIME_THRESHOLD}) =>{
  const wasOnTimeLowTriggered = useRef(false)

  const calculateTimeLeft = (): number => {
    const now = Date.now()
    const timeLeft = endTime.getTime() - now
    return timeLeft > 0 ? timeLeft : 0
  }

  const [timeLeft,setTimeLeft] = useState<number>(calculateTimeLeft())
    
  useEffect(() => {
    let intervalId = 0

    const updateTimer = ()=>{
      const currTimeLeft = calculateTimeLeft()

      if (currTimeLeft <= 0) {
        setTimeLeft(0)
        window.clearInterval(intervalId)
        onTimeEnd && onTimeEnd()
      }

      const triggerOnTimeLow =
          !wasOnTimeLowTriggered.current
          && currTimeLeft <= lowTimeThreshold

      if (triggerOnTimeLow) {
        onTimeLow && onTimeLow()
        wasOnTimeLowTriggered.current = true
      }

      setTimeLeft(()=>currTimeLeft)
    }

    updateTimer() //update once on load
    intervalId = window.setInterval(updateTimer, UPDATE_INTERVAL)
      
    return ()=>{
      window.clearInterval(intervalId)
    }
  }, [endTime])

  const formatForDisplay = (timeInMs: number) => {
    const totalSeconds = Math.ceil(timeInMs / 1000)
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = totalSeconds % 60

    const paddedSeconds = String(seconds).padStart(2, "0")
    const paddedMinutes = String(minutes).padStart(2, "0")

    return `${paddedMinutes}:${paddedSeconds}`
  }
    
  return <div>{formatForDisplay(timeLeft)}</div>
}

export default CountDown