import React, { FC, useMemo } from "react"
import clsx from "clsx"

import doctorAvatarPlaceholder1 from "../../../../assets/images/doctorAvatarPlaceholder1.png"
import doctorAvatarPlaceholder2 from "../../../../assets/images/doctorAvatarPlaceholder2.png"
import { useAppSelector } from "../../../../hooks/storeHooks"
import { selectClinicSettings } from "../../../../store/clinic/clinicSettings.selectors"
import useImageByIntegrationType from "../../../imageByIntegrationType/useImageByIntegrationType"
import ImageAvatar, { ImageAvatarProps } from "../ImageAvatar.component"
import { useAvatarSectionStyles } from "../../../services/comingServiceSections/avatarSection/AvatarSection.styles"

interface DoctorImageAvatarProps extends ImageAvatarProps {
  pseudorandomBoolean?: boolean
}

const DoctorImageAvatar: FC<DoctorImageAvatarProps> = (
  {
    src,
    alt,
    className,
    pseudorandomBoolean,
  },
) => {
  const {
    clinicAllianzSettings: {
      ewkaVerificationEnabled,
      onyxVerificationEnabled
    }
  } = useAppSelector(selectClinicSettings)
  const classes = useAvatarSectionStyles()
  const isAllianzEnabled = ewkaVerificationEnabled || onyxVerificationEnabled
    
  let placeHolderImageName =
        pseudorandomBoolean
          ? "doctorAvatarPlaceholder1.png"
          : "doctorAvatarPlaceholder2.png"
    
  if (isAllianzEnabled) {
    placeHolderImageName = "doctorAvatarPlaceholder2.png"
  }  

  const placeholderImage = useMemo(() => {
    if (isAllianzEnabled) {
      return doctorAvatarPlaceholder2
    }

    return pseudorandomBoolean 
      ? doctorAvatarPlaceholder1
      : doctorAvatarPlaceholder2
  }, [pseudorandomBoolean])

  const { imageSrcState } = useImageByIntegrationType(placeHolderImageName)

  return (
    <ImageAvatar
      src={src || imageSrcState || placeholderImage}
      alt={alt}
      className={
        clsx(
          className,
          isAllianzEnabled && !src
            ? classes.allianzDoctorImage
            : null
        )
      }
    />
  )
}

export default DoctorImageAvatar
