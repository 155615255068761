import i18next from "i18next"

import {removeTrailingSlash} from "../../utils/removeTrailingSlash"

export const loginLogoutRedirectUrl = (): string => {
  let currentUrlParams = window.location.pathname.replace(`/${i18next.language}`, "").replace("/login", "")
  currentUrlParams = removeTrailingSlash(currentUrlParams)

  return (
    currentUrlParams.length
      ? `/${i18next.language}/login/${currentUrlParams}`.replace("//", "/")
      : `/${i18next.language}/login`
  ) + window.location.search
}
