import React, {FC, useState} from "react"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {useHistory} from "react-router"
import {Redirect} from "react-router-dom"
import {Box, Divider, Typography, useMediaQuery, useTheme} from "@material-ui/core"

import api from "../../api/api"
import {
  getDependentAccountMagicLink,
} from "../../api/routes"
import {
  MAIN_ACCOUNT_FIRST_NAME,
  MAIN_ACCOUNT_LAST_NAME,
  MAIN_ACCOUNT_MAGIC_LINK} from "../../app.config"
import {useAppSelector} from "../../hooks/storeHooks"
import {selectChildrenAccounts, selectUser} from "../../store/user/user.selectors"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import CenteredPageSection from "../../components/common/pageSection/centeredPageSection/CenteredPageSection.component"
import {checkFeatureAvailability} from "../../store/clinic/clinicSettings.utils"

import SwitchAccountButton from "./SwitchAccountButton"

interface SwitchAccountPageProps {
  highlightCurrentAccount?: boolean;
}

const SwitchAccountPage: FC<SwitchAccountPageProps> = ({highlightCurrentAccount = true}) => {
  const {t, i18n} = useTranslation()
  const theme = useTheme()
  const currentUser = useAppSelector(selectUser)
  const { push } = useHistory()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const childrenAccounts = useSelector(selectChildrenAccounts)

  const [isSwitchingAccount, setIsSwitchingAccount] = useState<boolean>(false)

  const mainUser = currentUser?.user_parent || currentUser
  const isCurrentUserMain = mainUser?.pesel === currentUser?.pesel
  const dependentAccountsEnabled = checkFeatureAvailability().dependentAccountsEnabled
  const mainAccountFirstName = (isCurrentUserMain ? mainUser?.first_name : localStorage.getItem(MAIN_ACCOUNT_FIRST_NAME)) ?? null
  const mainAccountLastName = (isCurrentUserMain ? mainUser?.last_name : localStorage.getItem(MAIN_ACCOUNT_LAST_NAME)) ?? null

  const handleLoginToDependentAccount = async (policyUID:string, isCurrentlyLoggedIn:boolean)=>{
    if( !policyUID ){
      return
    }

    if ( isCurrentlyLoggedIn ){
      push(`/${i18n.language}`)
      return
    }

    setIsSwitchingAccount(true)

    try {
      const { data : magicLinks } = await api.request({
        ...getDependentAccountMagicLink(policyUID)
      })

      const mainAccountMagicLink = magicLinks?.main_account_magic_link
      const dependantAccountMagicLink = magicLinks?.magic_link
      const mainAccountFirstName = magicLinks?.main_account_first_name
      const mainAccountLastName = magicLinks?.main_account_last_name

      if( !mainAccountMagicLink || !dependantAccountMagicLink ){
        setIsSwitchingAccount(false)
        return
      }

      if( !currentUser?.user_parent ) {
        localStorage.setItem(MAIN_ACCOUNT_MAGIC_LINK, mainAccountMagicLink)
        localStorage.setItem(MAIN_ACCOUNT_FIRST_NAME, mainAccountFirstName)
        localStorage.setItem(MAIN_ACCOUNT_LAST_NAME, mainAccountLastName)
      }

      window.location.href = dependantAccountMagicLink
    } catch (e) {
      setIsSwitchingAccount(false)
      // console.error(e)
    }
  }

  const handleLoginToMainAccount = (isCurrentlyLoggedIn:boolean)=>{
    if ( isCurrentlyLoggedIn ){
      push(`/${i18n.language}`)
      return
    }

    const mainAccountMagicLink = localStorage.getItem(MAIN_ACCOUNT_MAGIC_LINK)
    if( !mainAccountMagicLink ){
      return
    }

    localStorage.removeItem(MAIN_ACCOUNT_MAGIC_LINK)
    localStorage.removeItem(MAIN_ACCOUNT_FIRST_NAME)
    localStorage.removeItem(MAIN_ACCOUNT_LAST_NAME)

    window.location.href = mainAccountMagicLink
  }

  if ( !dependentAccountsEnabled ){
    return <Redirect to={""}/>
  }

  if ( isSwitchingAccount || !mainUser || !currentUser ){
    return <LoaderBox size={theme.spacing(8)} thickness={2}/>
  }

  return (
    <CenteredPageSection title={t("user:chooseAccount")}>
      <Box
        display="flex"
        borderRadius={theme.shape.borderRadius}
        bgcolor={theme.palette.background.paper}
        gridRowGap={theme.spacing(4)}
        flexDirection={"column"}
        py={7.5}
        px={5.5}
        width={isMdUp ? "max(38vw,560px)" : "inherit"}
      >
        <Box
          display="flex"
          gridRowGap={theme.spacing(3)}
          flexDirection={"column"}
        >
          <Typography variant="h3" component="h3">
            { t("user:mainAccount") }:
          </Typography>

          <SwitchAccountButton
            name={mainAccountFirstName}
            surname={mainAccountLastName}
            onClickHandler={()=>handleLoginToMainAccount(isCurrentUserMain)}
            isCurrentlyLoggedIn={isCurrentUserMain}
            highlightCurrentAccount={highlightCurrentAccount}
          />
        </Box>

        { childrenAccounts &&
            <Box
              display="flex"
              gridRowGap={theme.spacing(2)}
              flexDirection={"column"}
            >
              <Divider color={theme.palette.grey["100"]}/>

              <Box
                display="flex"
                gridRowGap={theme.spacing(2)}
                flexDirection={"column"}
              >
                <Typography variant="h3" component="h3">
                  { t("user:childrenAccounts") }:
                </Typography>

                <Box
                  display="flex"
                  gridRowGap={theme.spacing(2)}
                  flexDirection={"column"}
                >
                  {childrenAccounts?.map((child)=>{
                    const policyUID = child.policy_uid
                    const isCurrentlyLoggedIn = child.pesel === currentUser.pesel

                    return <SwitchAccountButton
                      key={policyUID}
                      name={child.first_name}
                      surname={child.last_name}
                      isCurrentlyLoggedIn={isCurrentlyLoggedIn}
                      onClickHandler={()=>handleLoginToDependentAccount(policyUID,isCurrentlyLoggedIn)}
                      highlightCurrentAccount={highlightCurrentAccount}
                    />
                  })}
                </Box>
              </Box>
            </Box>
        }
      </Box>

    </CenteredPageSection>
  )
}

export default SwitchAccountPage
