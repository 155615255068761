import React, {FC, useState} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography, useMediaQuery, useTheme} from "@material-ui/core"

import CountDown from "../../../common/countDown/CountDown.component"
import {useInformationPanelStyles} from "../../InformationPanel.styles"
import {useFillUserDataCountDownStyles} from "./FillUserDataCountDown.styles"

interface FillUserDataCountDownProps{
  endTime: Date;
  millisecondsToFill: number;
  onTimeEnd?: () => void;
}

const FillUserDataCountDown: FC<FillUserDataCountDownProps> = ({endTime, onTimeEnd, millisecondsToFill}) => {
  const { t } = useTranslation()
  const classes = useFillUserDataCountDownStyles()

  const [isTimeLow, setIsTimeLow] = useState<boolean>(false)
  const [hideTimer, setHideTimer] = useState<boolean>(false)

  const getFormattedMaxTime = () => {
    const totalSeconds = Math.ceil(millisecondsToFill / 1000)
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = totalSeconds % 60

    let paddedSeconds = ""
    if (seconds){
      paddedSeconds = ":"+String(seconds).padStart(2, "0")
    }

    return minutes 
      ? `${minutes}${paddedSeconds} min`
      : `${seconds} s`
  }
    
  const onTimeLow = () => {
    setIsTimeLow(true)
  }

  const onTimeEnded = () => {
    onTimeEnd && onTimeEnd()
    setHideTimer(true)
  }
  
  if (hideTimer) {
    return null
  }
  
  return(
    <Box className={classes.container}>
      <Typography
        variant="h4"
        component="div"
      >
        { t("user:fillDataTimeConstraint", {time: getFormattedMaxTime() }) }
      </Typography>
      <Typography
        variant="body1"
        component="div"
      >
        { t("user:fillDataOnTimeEnd") }
      </Typography>

      <Box 
        className={classes.timerContainer}
      >
        <Typography
          className={`
              ${classes.timerBox}
              ${isTimeLow ? classes.lowTime: ""}
          `}
          variant="h2"
          component="div"
        >
          <CountDown
            endTime={endTime}
            onTimeEnd={onTimeEnded}
            onTimeLow={onTimeLow}
          />
        </Typography>
      </Box>
        
    </Box>
  ) 
}

export default FillUserDataCountDown