import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"

import { ReactComponent as CheckIcon } from "../../../assets/svg/check-fill.svg"
import { APP_READABLE_CURRENCY } from "../../../constants/defaults"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import Button from "../../button/Button.component"
import VoucherCodeToBuyLogo from "./VoucherCodeToBuyLogo.component"
import { checkFeatureAvailability } from "../../../store/clinic/clinicSettings.utils"
import { VoucherTypes } from "../voucherBox/VoucherBox.utils"
import { getVoucherDescriptionTranslationKeyByCode, voucherCodeToBuyTranslation } from "./VoucherCodeToBuy.utils"
import { useVoucherCodeToBuyStyles } from "./VoucherCodeToBuy.styles"

interface VoucherBoxProps {
  voucherCodeToBuy: string;
}

const VoucherCodeToBuy: FC<VoucherBoxProps> = ({voucherCodeToBuy}) => {
  const { t, i18n } = useTranslation()
  const { push } = useHistory()

  const classes = useVoucherCodeToBuyStyles()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"))

  const handleClick = () => {
    push(`${i18n.language}/vouchers/buy`)
  }

  const getTelemediGoDiscountBox = () => {
    const discountText = <Typography 
      variant="body1" 
      className={clsx(
        classes.discounted, 
        classes.telemediGoDiscounted,
      )}>
      {t("finances:voucherCodeToBuy:telemedi-go:customInfo:discount")}
    </Typography>

    if (checkFeatureAvailability().isTelemediGoForSpecialistsEnabled) {
      return <Box className={classes.specialistsTelemediGoDiscountedBox}>
        <Typography variant="body1" className={classes.specialistsTelemediGoDiscounted}>
          {t("finances:voucherCodeToBuy:telemedi-go:customInfo:specialists:discount")}
        </Typography>
        { discountText }
      </Box>
    }

    return discountText
  }
  
  const FeatureSection = ({featureKey}: {featureKey: string})  => <Box flexDirection="row" display="flex">
    <Box className={classes.checkIcon} >
      <ImageByIntegrationType
        imageSrc={<CheckIcon className={classes.checkIconImg}/>}
        imagePath={"check-fill.svg"}
        className={classes.checkIconImg}
        returnSvg={true}
      />
    </Box>
    <Typography 
      variant="body2"
      dangerouslySetInnerHTML={{ 
        __html: voucherCodeToBuyTranslation(featureKey, voucherCodeToBuy) 
      }}
    >
    </Typography>
  </Box>

  if (voucherCodeToBuy === VoucherTypes.TYPE_TELEMEDI_GO) {
    return (
      <Box
        className={clsx(classes.detailsContainer, classes.detailsBox, isSmUp ? "" : classes.leftSpacing)}
        color={theme.palette.secondary.contrastText}
      >
        <Box className={clsx(classes.borderBox, classes.fullWidthContainer)}>
          <Box
            display="flex"
            justifyContent="space-between"
            className={classes.sectionsContainer}
            pt={0}
          >
            <Grid
              className={clsx(
                classes.detailsContainer, 
                classes.telemediGoDetailsContainer,
                checkFeatureAvailability().isTelemediGoForSpecialistsEnabled
                  ? classes.telemediGoSpecialistsDetailsContainer
                  : null
              )}
              container
              justifyContent={isSmUp ? "flex-start" : "space-between"}
              alignItems="stretch"
              spacing={!isSmUp ? 3: 0}
            >
              <Grid container item xs={12} md={3} className={isSmUp ? clsx(classes.selfCentered, classes.telemediGoGridContainer) : undefined}>
                <VoucherCodeToBuyLogo voucherCode={voucherCodeToBuy} />
              </Grid>
              <Grid 
                item md={2} 
                className={clsx(
                  classes.telemediGoDiscountContainer,
                  checkFeatureAvailability().isTelemediGoForSpecialistsEnabled 
                    ? classes.telemediGoDiscountContainerForSpecialists 
                    : null
                )}>
                <Box mt={3}/>
                { getTelemediGoDiscountBox() }
                <Box mt={0} ml={1}>
                  <Typography variant="body2">
                    {voucherCodeToBuyTranslation("description", voucherCodeToBuy)}
                  </Typography>
                  <Box>
                    <FeatureSection featureKey={getVoucherDescriptionTranslationKeyByCode("feature1")} />
                    <FeatureSection featureKey={
                      isSmUp
                        ? getVoucherDescriptionTranslationKeyByCode("feature2")
                        : getVoucherDescriptionTranslationKeyByCode("feature2Short")
                    } />
                    <FeatureSection featureKey={
                      isSmUp
                        ? getVoucherDescriptionTranslationKeyByCode("feature3")
                        : getVoucherDescriptionTranslationKeyByCode("feature3Short")
                    } />
                  </Box>
                  <Box mt={1} className={clsx(classes.greenTextColor, classes.featureDescription)}>
                    {t("finances:voucherCodeToBuy:telemedi-go:featureDescription")}
                  </Box>
                </Box>
              </Grid>
              <Grid item md={6} className={classes.telemediGoCheckboxContainer}>
                <Box mt={!isSmUp ? 4 : 0} className={classes.telemediGoCheckboxBox}>
                  <Box mt={1} className={clsx(classes.greenTextColor, classes.featureDescriptionMobile)}>
                    {t("finances:voucherCodeToBuy:telemedi-go:featureDescription")}
                  </Box>
                  <Typography variant="body1" className={clsx(classes.checkboxDescription, classes.greenTextColor, classes.telemediGoCheckboxDescription)}>
                    {
                      t(`finances:voucherCodeToBuy:telemedi-go:customInfo:${getVoucherDescriptionTranslationKeyByCode("checkboxDescription")}`)
                    }
                  </Typography>
                  <Button variant="contained" 
                    color="inherit" 
                    className={clsx(classes.buyButton, classes.telemediGoBuyButton)}
                    fullWidth={!isSmUp} 
                    onClick={handleClick}
                  >
                    {t("finances:voucherCodeToBuy:telemedi-go:customInfo:buy")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      className={clsx(classes.detailsContainer, classes.detailsBox, isSmUp ? "" : classes.leftSpacing)}
      color={theme.palette.secondary.contrastText}
    >
      <Box className={classes.borderBox}>
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.sectionsContainer}
          pt={0}
        >
          <Grid
            className={classes.detailsContainer}
            container
            justifyContent={isSmUp ? "flex-start" : "space-between"}
            alignItems="stretch"
            spacing={!isSmUp ? 3: 0}
          >
            <Grid container item xs={12} md={3} className={isSmUp ? classes.selfCentered : undefined}>
              <VoucherCodeToBuyLogo voucherCode={voucherCodeToBuy} />
            </Grid>
            <Grid item md={2}>
              <Typography variant="body1" className={clsx(classes.boldText, classes.costText)}>
                {t("finances:voucherCodeToBuy:cost")} {" "}
                <span className={clsx(classes.currency, classes.stroke)}>
                  {voucherCodeToBuyTranslation("cost", voucherCodeToBuy)} {APP_READABLE_CURRENCY}
                </span>
              </Typography>
              <Box mt={3}/>
              <Typography variant="body1" className={classes.discounted}>
                {voucherCodeToBuyTranslation("discounted", voucherCodeToBuy)}
                <span className={clsx(classes.currency, classes.discountedCurrency)}>
                  {APP_READABLE_CURRENCY}
                </span>
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Box mt={0}>
                <Typography variant="body1" className={classes.boldText}>
                  {voucherCodeToBuyTranslation("description", voucherCodeToBuy)}
                </Typography>
                <Box mt={2}>
                  <FeatureSection featureKey={"feature1"} />
                  <FeatureSection featureKey={"feature2"} />
                </Box>
              </Box>
              <Box ml={4} textAlign={isSmUp ? "right": "center"} mt={!isSmUp ? 4 : 0}>
                <Button variant="contained" 
                  color="inherit" 
                  className={classes.buyButton} 
                  fullWidth={!isSmUp} 
                  onClick={handleClick}
                >
                  {t("finances:voucherCodeToBuy:buy")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default VoucherCodeToBuy
