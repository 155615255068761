export enum RoutePath {
  HOMEPAGE = "/",
  LOGIN = "/login/:ref?/:ref2?/:ref3?",
  LOGIN_MAGIC_LINK = "/sms/:magicLinkId",
  PASSWORDLESS_ACCESS_CODE = "/passwordless/verify",
  PASSWORDLESS_BIRTHDAY_VERIFY = "/passwordless/birthday-verify",
  PASSWORDLESS_MAGIC_LINK = "/passwordless/login/:code/:magicLinkId",
  MEDICAL_QUESTIONNAIRE = "/medical-questionnaire/:consultationId",
  FORGOT_PASSWORD = "/forgot-password",
  PREVENTION = "/prevention",
  PREVENTION_ACTION = "/prevention/action/:actionId",
  PREVENTION_ACTION_SUMMARY = "/prevention/action/:actionId/summary",
  PREVENTION_QUESTIONNAIRE = "/prevention/questionnaires/:questionnaireId",
  PREVENTION_DIARY = "/prevention/diary/:name",
  PREVENTION_DIARY_BLOOD_PRESSURE = "/prevention/diary/blood_pressure",
  PREVENTION_DIARY_PULSE = "/prevention/diary/pulse",
  PREVENTION_DIARY_WEIGHT = "/prevention/diary/weight",
  PREVENTION_DIARY_GLYCEMIA = "/prevention/diary/glycemia",
  PREVENTION_DIARY_WAIST_MEASUREMENT = "/prevention/diary/waist_measurement",
  PREVENTION_DISEASES = "/prevention/diseases",
  PREVENTION_DISEASES_QUESTIONNAIRE = "/prevention/diseases/:diseaseId/questionnaire",
  PREVENTION_DISEASE_DETAILS = "/prevention/diseases/:diseaseId",
  PREVENTION_AWARDS = "/prevention/awards",
  PREVENTION_AWARDS_AWARD = "/prevention/awards/:awardId",
  PREVENTION_BMI_ACTION = "/prevention/bmi-action",
  PREVENTION_MESSENGER_CHAT = "/prevention/chat/:threadId",
  DOCUMENTATION = "/documentation",
  NOTIFICATIONS = "/notifications/:notificationId?",
  CONSULTATION_CHAT = "/consultation/:consultationId?/:additionalParam?",
  CANCEL_CONSULTATION = "/consultation/:consultationId/confirm-cancel",
  CONSULTATION_PAYMENT_SUCCESSFULL = "/consultation-payment-successful/:consultationId?",
  CONSULTATION_PAYMENT = "/consultation/payment/:consultationId?",
  STATIONARY_CONSULTATION_PAYMENT_SUCCESSFULL = "/stationary-consultation-payment-successful",
  CONSULTATION_PAYMENT_DECLINED = "/consultation-payment-declined",
  CONSULTATION_PAYMENT_STATUS = "/consultation-payment-status/:consultationId?",
  PAYMENT_STATUS = "/payment-status/:paymentId?",
  PAYMENT_DECLINED = "/payment/declined",
  PAYMENT_SUCCESS = "/payment/success",
  VOUCHER_PAYMENT_SUCCESS = "/voucher/payment/success",
  VOUCHER_PAYMENT_DECLINED = "/voucher/payment/declined",
  CONSULTATION_CHANGE_TERM = "/consultation-change-term/:consultationId?",
  CONSULTATION_TERM_SELECTION = "/consultation-term-selection/:consultationId?",
  CONSULTATION_CHOOSE_TERM = "/consultations/term/:consultationId",
  CONSULTATION_CANCEL_SUCCESS = "/consultation-cancelled-successfully",
  EXAMS_PAYMENT_SUCCESSFULL = "/exams-payment-successful",
  USER_PROFILE = "/user-profile",
  ORDER_EXAM = "/order-exams/:consultationId?",
  ORDER_EXAMINATIONS_BY_ALAB = "/order-examinations/:consultationId",
  ORDER_MEDICINES = "/order-medicines/:consultationId",
  MAKE_CONSULTATION = "/make-consultation",
  MAKE_PRESCRIPTION_CONSULTATION = "/make-prescription-consultation",
  MAKE_SICK_LEAVE_CONSULTATION = "/make-sick-leave-consultation",
  MAKE_STATIONARY_CONSULTATION = "/make-stationary-consultation",
  MEDICAL_SURVEY = "/medical-survey/:consultationId",
  POSSIBILITIES = "/possibilities",
  LOYALITY_PROGRAM = "/loyality",
  NFZ_RESIGN = "/nfz-resign",
  ERROR_404 = "/404",
  ERROR_403 = "/403",
  SERVER_ERROR = "/server-error/:code",
  FALLBACK = "/*",
  SICK_LEAVE_SURVEY = "/sick-leave-survey/:consultationId?",
  CONSULTATION_SURVEY = "/consultation-survey/:consultationId?",
  CLINIC_NOT_FOUND = "/clinic-not-found",
  DECLARATION_SIGN_STATUS_PAGE = "/check-declaration/:id?",
  CONSULTATION_NEW_TERM = "/consultation-new-term/:consultationId?",
  ERROR_504 = "/504",
  EMAIL_STATIONARY_CONSULTATION = "/make-stationary",
  VOUCHERS_BUY = "/vouchers/buy",
  EXAM_PAYMENT_STATUS = "/exam-payment-status/:examCartId?",
  EXAM_PAYMENT_DECLINED = "/exam-payment-declined",
  SWITCH_ACCOUNT = "/switch-account",
  MENTAL_HEALTH = "/mental-health",
  HEALTH_PREVENTION_IFRAME_VIEW = "/health-prevention/iframe/view",
  MENTAL_HEALTH_LIBRARY = "/mental-health/library",
  MENTAL_HEALTH_WEBINAR = "/mental-health/webinar",
  MENTAL_HEALTH_SELF_IMPROVEMENT = "/mental-health/self-improvement",
}

/*
* Some routes link to an outside service in an iframe (ex.stationaryWidget)
* that fetches user data itself, we keep track of them to avoid double fetching
*/
export const routesThatFetchUserDataThemselves = [
  RoutePath.MAKE_STATIONARY_CONSULTATION,
  RoutePath.MAKE_CONSULTATION,
  RoutePath.MAKE_PRESCRIPTION_CONSULTATION,
]
