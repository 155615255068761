import {UserAddressModel} from "../../components/user/User.types"
import {isAdult} from "../../utils/isAdult"
import {RootState} from "../store.types"
import {DependentAccountType, UserDataState, UserDataType} from "./user.types"

export const selectLoading: ((state: RootState) => UserDataState["loading"]) = ({user}) => user.loading

export const selectPozDeclarationActiveStatus: ((state: RootState) => UserDataState["isActiveDeclaration"]) = ({user}) => {
  return user.isActiveDeclaration
}

export const selectError: ((state: RootState) => UserDataState["error"]) = ({user}) => user.error

export const selectUser: ((state: RootState) => UserDataState["userData"]) = ({user}) => user.userData

export const selectUserInitialLoading: ((state: RootState) => boolean) = ({user}) => {
  return user.loading && !user.userData
}

export const selectUserPozDeclarationActiveStatus: ((state: RootState) => boolean | null) = ({user}) => {
  return user.userData ? user.userData.isPozDeclarationActive : null
}

export const selectHasUserCompletePersonalData: ((state: RootState) => boolean | null) = ({user}) => {
  return user.userData ? user.userData.hasCompletePersonalData : null
}

export const selectUserFirstName: ((state: RootState) => string) = ({user}) => {
  return user.userData ? user.userData.first_name : ""
}

export const selectUserLastName: ((state: RootState) => string) = ({user}) => {
  return user.userData ? user.userData.last_name : ""
}

export const selectUserAddress: ((state: RootState) => UserAddressModel | null) = ({user}) => {
  return user.userData ? user.userData.address : null
}

export const selectUserAddressCoords: ((state: RootState) => google.maps.LatLngLiteral | null) = ({user}) => {
  return user.userAddressCoords
}

export const selectUserAddressCoordsLoading: ((state: RootState) => boolean) = ({user}) => {
  return user.userAddressCoordsLoading
}

export const selectUserAddressCoordsError: ((state: RootState) => UserDataState["userAddressCoordsError"]) = ({user}) => {
  return user.userAddressCoordsError
}

export const selectUserAddressEditModalOpen: ((state: RootState) => boolean) = ({user}) => {
  return user.userAddressEditModalOpen
}

export const selectUserRegulationsModalOpen: ((state: RootState) => boolean) = ({user}) => {
  return user.userRegulationsModalOpen
}

export const selectUserForcePasswordChangeModalOpen: ((state: RootState) => boolean) = ({user}) => {
  return user.userForcePasswordChangeModalOpen
}

export const selectRandomSessionId: ((state: RootState) => UserDataState["randomSessionId"]) = ({user}) => {
  return user.randomSessionId
}

export const selectIsEpuapReSignFlowRequired: ((state: RootState) => UserDataType["isEpuapReSignFlowRequired"]) = ({user}) => {
  return  !!user?.userData?.isEpuapReSignFlowRequired
}

export const selectUserActiveDeclarationType: ((state: RootState) => UserDataType["poz"]["active_declaration_type"] | undefined) = ({user}) => {
  return user?.userData?.poz?.active_declaration_type
}

export const selectDependentAccounts: ((state: RootState) => UserDataState["dependentAccounts"] | null) = ({user}) => {
  return user?.dependentAccounts
}

export const selectChildrenAccounts: ((state: RootState) => UserDataState["dependentAccounts"] | null) = ({user}) => {
  const childrenAccounts = user?.dependentAccounts?.filter((dependentAccount: DependentAccountType)=>{
    return !isAdult(dependentAccount.birth_date)
  })
  return childrenAccounts ?? null
}

export const selectHasDependentAccounts: ((state: RootState) => boolean) = ({user}) => {
  return !!user?.dependentAccounts && user.dependentAccounts.length > 0
}
