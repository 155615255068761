import { dates } from "./common/dates"
import awards from "./lokalise/ba/awards.json"
import b2bClinic from "./lokalise/ba/b2bClinic.json"
import calendar from "./lokalise/ba/calendar.json"
import chatConsultation from "./lokalise/ba/chatConsultation.json"
import consultation from "./lokalise/ba/consultation.json"
import consultationNestedMenu from "./lokalise/ba/consultationNestedMenu.json"
import defaultTranslations from "./lokalise/ba/defaultTranslations.json"
import diaryMeasurement from "./lokalise/ba/diaryMeasurement.json"
import diseaseManagement from "./lokalise/ba/diseaseManagement.json"
import documents from "./lokalise/ba/documents.json"
import emptyConsultationsBox from "./lokalise/ba/emptyConsultationsBox.json"
import errors from "./lokalise/ba/errors.json"
import exam from "./lokalise/ba/exam.json"
import finances from "./lokalise/ba/finances.json"
import forgotPassword from "./lokalise/ba/forgotPassword.json"
import healthPlanner from "./lokalise/ba/healthPlanner.json"
import healthPlannerActions from "./lokalise/ba/healthPlannerActions.json"
import laboratory from "./lokalise/ba/laboratory.json"
import login from "./lokalise/ba/login.json"
import loginPagePopup from "./lokalise/ba/loginPagePopup.json"
import medicalQuestionnaire from "./lokalise/ba/medicalQuestionnaire.json"
import mentalHealth from "./lokalise/ba/mentalHealth.json"
import notifications from "./lokalise/ba/notifications.json"
import orderMedicines from "./lokalise/ba/orderMedicines.json"
import pageFooter from "./lokalise/ba/pageFooter.json"
import passwordless from "./lokalise/ba/passwordless.json"
import payments from "./lokalise/ba/payments.json"
import prescription from "./lokalise/ba/prescription.json"
import preventionQuestionnaires from "./lokalise/ba/preventionQuestionnaires.json"
import recommendations from "./lokalise/ba/recommendations.json"
import referrals from "./lokalise/ba/referrals.json"
import sessionManager from "./lokalise/ba/sessionManager.json"
import stagingSettings from "./lokalise/ba/stagingSettings.json"
import user from "./lokalise/ba/user.json"
import videoChatConsultation from "./lokalise/ba/videoChatConsultation.json"

const ba = {
  medicalQuestionnaire,
  defaultTranslations,
  dates,
  consultation,
  chatConsultation,
  videoChatConsultation,
  user,
  recommendations,
  exam,
  prescription,
  emptyConsultationsBox,
  login,
  forgotPassword,
  referrals,
  calendar,
  errors,
  sessionManager,
  notifications,
  documents,
  finances,
  pageFooter,
  laboratory,
  stagingSettings,
  payments,
  b2bClinic,
  orderMedicines,
  preventionQuestionnaires,
  awards,
  diaryMeasurement,
  healthPlannerActions,
  diseaseManagement,
  healthPlanner,
  consultationNestedMenu,
  passwordless,
  loginPagePopup,
  mentalHealth
}

export default ba
