import { dates } from "./common/dates"
import awards from "./lokalise/bg/awards.json"
import b2bClinic from "./lokalise/bg/b2bClinic.json"
import calendar from "./lokalise/bg/calendar.json"
import chatConsultation from "./lokalise/bg/chatConsultation.json"
import consultation from "./lokalise/bg/consultation.json"
import consultationNestedMenu from "./lokalise/bg/consultationNestedMenu.json"
import defaultTranslations from "./lokalise/bg/defaultTranslations.json"
import diaryMeasurement from "./lokalise/bg/diaryMeasurement.json"
import diseaseManagement from "./lokalise/bg/diseaseManagement.json"
import documents from "./lokalise/bg/documents.json"
import emptyConsultationsBox from "./lokalise/bg/emptyConsultationsBox.json"
import errors from "./lokalise/bg/errors.json"
import exam from "./lokalise/bg/exam.json"
import finances from "./lokalise/bg/finances.json"
import forgotPassword from "./lokalise/bg/forgotPassword.json"
import healthPlanner from "./lokalise/bg/healthPlanner.json"
import healthPlannerActions from "./lokalise/bg/healthPlannerActions.json"
import laboratory from "./lokalise/bg/laboratory.json"
import login from "./lokalise/bg/login.json"
import loginPagePopup from "./lokalise/bg/loginPagePopup.json"
import medicalQuestionnaire from "./lokalise/bg/medicalQuestionnaire.json"
import mentalHealth from "./lokalise/bg/mentalHealth.json"
import notifications from "./lokalise/bg/notifications.json"
import orderMedicines from "./lokalise/bg/orderMedicines.json"
import pageFooter from "./lokalise/bg/pageFooter.json"
import passwordless from "./lokalise/bg/passwordless.json"
import payments from "./lokalise/bg/payments.json"
import prescription from "./lokalise/bg/prescription.json"
import preventionQuestionnaires from "./lokalise/bg/preventionQuestionnaires.json"
import recommendations from "./lokalise/bg/recommendations.json"
import referrals from "./lokalise/bg/referrals.json"
import sessionManager from "./lokalise/bg/sessionManager.json"
import stagingSettings from "./lokalise/bg/stagingSettings.json"
import user from "./lokalise/bg/user.json"
import videoChatConsultation from "./lokalise/bg/videoChatConsultation.json"

const bg = {
  medicalQuestionnaire,
  defaultTranslations,
  dates,
  consultation,
  chatConsultation,
  videoChatConsultation,
  user,
  recommendations,
  exam,
  prescription,
  emptyConsultationsBox,
  login,
  forgotPassword,
  referrals,
  calendar,
  errors,
  sessionManager,
  notifications,
  documents,
  finances,
  pageFooter,
  laboratory,
  stagingSettings,
  payments,
  b2bClinic,
  orderMedicines,
  preventionQuestionnaires,
  awards,
  diaryMeasurement,
  healthPlannerActions,
  diseaseManagement,
  healthPlanner,
  consultationNestedMenu,
  passwordless,
  loginPagePopup,
  mentalHealth
}

export default bg
