import React, {FC, useEffect, useRef, useState} from "react"
import {Controller, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {RouteComponentProps, useHistory} from "react-router"
import {
  Box,
  Button,
  ButtonBase,
  Container,
  Hidden,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core"

import useMakeQuery from "../../api/graphql/hooks/useMakeQuery"
import {
  appEnv,
  benefitSystemLoginUrl,
  isStagingApiEnv,
  mondialcareLoginUrl,
  registerAppUrl,
  SETTINGS_PANEL_ENABLE,
  ssoMiddlewareHost, storedMagicLinkKey
} from "../../app.config"
import {SsoErrorCode} from "../../enums/SsoErrorCode"
import {useAppDispatch} from "../../hooks/storeHooks"
import useGetFormValidationRules from "../../hooks/useGetFormValidationRules"
import useGetSubdomainName from "../../hooks/useGetSubdomainName"
import useIsB2BClinic from "../../hooks/useIsB2BClinic"
import {useRemoveIsFirstSessionInvoked} from "../../hooks/useRemoveIsFirstSessionInvoked"
import { analytics, LogEventType } from "../../services/analytics"
import {selectClinicSettings} from "../../store/clinic/clinicSettings.selectors"
import {selectLogInError, selectLogInLoading, selectLoginRedirectData} from "../../store/logIn/logIn.selectors"
import {clearRedirectData, logInError, logInSubmit, setRedirectUrlAfterLogIn} from "../../store/logIn/logIn.slice"
import {selectSelectedLoginAccount} from "../../store/loginAccounts/loginAccounts.selectors"
import {clearSelectedLoginAccount} from "../../store/loginAccounts/logInAccounts.slice"
import {selectSession} from "../../store/session/session.selectors"
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader.component"
import ButtonTextUnderlined from "../../components/common/buttonTextUnderlined/ButtonTextUnderlined.component"
import LangDropdowndOnBasePage from "../../components/common/langDropdown/LangDropdownOnBasePage.component"
import PageSection from "../../components/common/pageSection/PageSection.component"
import GlobalFormErrorMessage
  from "../../components/commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import CustomIntegrationPermissionsModal
  from "../../components/customIntegrationPermissionsModal/CustomIntegrationPermissionsModal.component"
import IconByIntegrationType from "../../components/iconByIntegrationType/IconByIntegrationType"
import SendTwoFactorCodeForm from "../../components/login/SendTwoFactorCodeForm.component"
import WrapperLoginAccountModal from "../../components/loginUsersModal/WrapperLoginAccountModal.component"
import PasswordlessBox from "../../components/passwordlessBox/PasswordlessBox.component"
import SendAccessCodeInput from "../../components/passwordlessBox/sendAccessCodeInput/SendAccessCodeInput.component"
import PasswordlessErrorMessage from "../../components/passwordlessErrorMessage/PasswordlessErrorMessage.component"
import SettingsPanel from "../../components/settingsPanel/SettingsPanel.component"
import V4SearchParamErrorMessage from "./v4SearchParamErrorMessage/V4SearchParamErrorMessage.component"
import {checkFeatureAvailability} from "../../store/clinic/clinicSettings.utils"
import {getRedirectUrl, IsUnauthenticated,IsUnauthenticatedAllianzUser} from "../../utils/isUnauthenticatedAllianzUser"
import {getUserNameLabel, goToDedicatedLoginPage} from "./LoginPage.utils"
import {RoutePath} from "../../routes/Routes.types"
import {MagicLink} from "../../store/clinic/clinicSettings.types"
import {LogInPageValues, LogInValidationError} from "./LoginPage.types"
import {useLoginPageStyles} from "./LoginPage.styles"

import {loginPageValidationSchema} from "./LoginPage.validation"

const LoginPage: FC<RouteComponentProps> = () => {
  useRemoveIsFirstSessionInvoked()

  const {t, i18n} = useTranslation()
  const currentUrlParams
      = window.location.pathname.replace(`/${i18n.language}/login/`, "/")
  const redirectUrl = currentUrlParams !== `/${i18n.language}/login` ? `/${i18n.language}${currentUrlParams}${window.location.search}` : null
  const classes = useLoginPageStyles()
  const {push} = useHistory()
  const dispatch = useAppDispatch()
  const isSettingsPanelVisible = SETTINGS_PANEL_ENABLE.includes(appEnv)
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const session = useSelector(selectSession)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [twoFactorFormVisible, setTwoFactorFormVisible] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(true)
  const loading = useSelector(selectLogInLoading) || session && Object.keys(session).length > 0
  const errorResponse = useSelector(selectLogInError)
  const selectedAccount = useSelector(selectSelectedLoginAccount)
  const differentRoleRedirectData = useSelector(selectLoginRedirectData)
  const {B2BClinic} = useIsB2BClinic()
  const clinicSettings = useSelector(selectClinicSettings)
  const {subdomain} = useGetSubdomainName()
  //TODO: https://telemedico.atlassian.net/browse/LESS-5237
  const isMondialcareClinic = subdomain === "mondialcare."
  const customIntegrationEnabled = checkFeatureAvailability().enableBenefitSystemIntegration || isMondialcareClinic
  const frontendSettings = clinicSettings?.frontendSettings
  const clinicLogoLogin = frontendSettings?.images?.logoLogin
  const magicLinks = clinicSettings?.notificationSettings?.magicLinks
  const isPasswordlessEnabled = magicLinks?.some(
    magicLink =>
      magicLink === MagicLink.TYPE_PATIENT_PASSWORDLESS_EMAIL ||
      magicLink === MagicLink.TYPE_PATIENT_PASSWORDLESS_SMS
  )
  const passwordLessInputRef = useRef<HTMLInputElement | null>(null)
  const customLogoType = clinicLogoLogin ? <img className={classes.logoLogin} src={clinicLogoLogin} alt="" /> : null
  const {getClinicIdQuery} = useMakeQuery()
  const clinicData = getClinicIdQuery(subdomain)
  const clinicId = clinicData?.data?.edges?.[0]?.node?.frontendSettings?.clinicId
  const errorData = errorResponse?.data
  const showAdditionalResetPasswordBtn = errorData?.error === LogInValidationError.CHANGE_PASSWORD_REQUIRED && showError
  const showAdditionalPasswordLessBtn = errorData?.error_reason === LogInValidationError.INVALID_USER_CREDENTIALS && isPasswordlessEnabled
  const magicLinkId = sessionStorage.getItem(storedMagicLinkKey)
  const globalErrorMessage = (() => {
    const additionalLoginInformation = errorData?.error_reason === LogInValidationError.INVALID_USER_CREDENTIALS ? t("errors:additionalLoginInformation") : ""

    if (showError) {
      if (errorData?.error === LogInValidationError.TWO_FACTOR_REQUIRED) {
        return
      } else if (errorData?.error === LogInValidationError.CHANGE_PASSWORD_REQUIRED) {
        return `${t("errors:change_password_administrative")} `
      } else if (errorData?.error_reason === LogInValidationError.INVALID_USER_CREDENTIALS) {
        return isPasswordlessEnabled
          ? `${t(`errors:${errorData?.error_reason}`)}${t("errors:additionalLoginInformationWithPasswordLess")} `
          : `${t(`errors:${errorData?.error_reason}`)}${additionalLoginInformation} `
      } else if (errorData?.error_reason ) {
        return `${t(`errors:${errorData?.error_reason}`)}${additionalLoginInformation} `
      } else if (errorData?.code === SsoErrorCode.ACCOUNT_ID_NOT_RECOGNIZED) {
        return t("errors:accountIdNotRecognized")
      }
      return t("errors:unknownError")
    }

    return ""
  })()

  if(IsUnauthenticatedAllianzUser() && !magicLinkId) {
    const externalRedirectUrl = getRedirectUrl()

    if (externalRedirectUrl) {
      window.location.href = externalRedirectUrl
      return null
    }
  }

  if(!IsUnauthenticated() && magicLinkId) {
    push(`/${i18n.language}${RoutePath.LOGIN_MAGIC_LINK.replace(":magicLinkId", magicLinkId)}`)
  }

  const form = useForm<LogInPageValues>({
    mode: "all",
    resolver: useGetFormValidationRules(loginPageValidationSchema),
    defaultValues: {
      password: "",
      username: ""
    }
  })

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const goToResetPage = () => {
    push(`/${i18n.language}/forgot-password`)
  }

  const goToPasswordLessLogin = () => {
    passwordLessInputRef?.current?.focus()
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    const body: LogInPageValues = {
      username: values.username,
      password: values.password
    }
    dispatch(logInError(null))
    dispatch(clearRedirectData())

    if (redirectUrl) {
      await dispatch(setRedirectUrlAfterLogIn(redirectUrl))
    }

    await dispatch(logInSubmit(body))
  })

  useEffect(() => {
    if (errorData?.error) {
      setShowError(true)
    }
  }, [errorData])

  useEffect(() => {
    if (errorResponse?.data?.error === LogInValidationError.TWO_FACTOR_REQUIRED) {
      setTwoFactorFormVisible(true)
    }
  }, [errorResponse])

  useEffect(() => {
    if (selectedAccount) {
      const loginCredentials = {
        userId: selectedAccount.id,
        password: form.getValues("password"),
      }
      dispatch(logInSubmit(loginCredentials))
      dispatch(clearSelectedLoginAccount())
    }
  }, [selectedAccount])

  useEffect(() => {
    setShowError(false)
    dispatch(clearRedirectData())
    if (session?.id) {
      push(`/${i18n.language}`)
    }
    analytics.sendEventWithDefaultParams(LogEventType.LOGIN_PAGE_LOADED)
  }, [])

  const titleNode = (
    <>
      <Box className={classes.logoLoginWrapper}>
        { customLogoType }
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Typography
          variant={isMdUp ? "h2" : "h4"}
          component="h2"
        >
          { t("login:logIn") }
        </Typography>

        <LangDropdowndOnBasePage/>
      </Box>
    </>
  )

  return (
    <>
      { isSettingsPanelVisible && (
        <SettingsPanel/>
      )}

      <Container
        maxWidth={"sm"}
        className={classes.root}
      >
        {
          twoFactorFormVisible
            ?
            <SendTwoFactorCodeForm
              setTwoFactorFormVisible={setTwoFactorFormVisible}
              MFACode={errorResponse?.data?.two_factor_id}
              methodId={errorResponse?.data?.two_factor_methods?.[0]?.id}
              methodType={errorResponse?.data?.two_factor_methods?.[0]?.method}
            />
            : (
              <PageSection titleNode={titleNode}>
                <>
                  <Box
                    borderRadius={theme.shape.borderRadius}
                    bgcolor={theme.palette.background.paper}
                    className={classes.pageBox}
                  >
                    <V4SearchParamErrorMessage/>
                    <PasswordlessErrorMessage/>
                    {(errorResponse && globalErrorMessage) && (
                      <GlobalFormErrorMessage
                        message={globalErrorMessage}
                        messageComponent={showAdditionalResetPasswordBtn || showAdditionalPasswordLessBtn ? (
                          <ButtonTextUnderlined
                            color="primary"
                            onClick={showAdditionalResetPasswordBtn ? goToResetPage : goToPasswordLessLogin}
                            className={classes.resetPasswordMsg}
                          >
                            {
                              showAdditionalResetPasswordBtn
                                ? t("login:resetPassword")
                                : t("passwordless:submitButtonLabel").toLowerCase()
                            }
                          </ButtonTextUnderlined>
                        ) : null}
                      />
                    )}
                    {
                      differentRoleRedirectData?.redirectLoginUrl && (
                        <GlobalFormErrorMessage
                          message={t("login:useDedicatedLoginPanel")}
                          messageComponent={
                            <ButtonTextUnderlined
                              color="primary"
                              onClick={() => goToDedicatedLoginPage(differentRoleRedirectData.redirectLoginUrl)}
                            >
                              <Typography variant="body1">
                                {differentRoleRedirectData.shortLoginUrl}
                              </Typography>
                            </ButtonTextUnderlined>
                          }
                        />
                      )
                    }
                    <form
                      autoComplete="off"
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      {!customIntegrationEnabled && (
                        <>
                          <Controller
                            name="username"
                            control={form.control}
                            render={(
                              {
                                field: {onChange, value},
                                fieldState: {error, invalid}
                              }
                            ) => (
                              <TextField
                                id="username"
                                label={getUserNameLabel(B2BClinic)}
                                placeholder={getUserNameLabel(B2BClinic)}
                                fullWidth
                                disabled={loading}
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error?.message}
                                required={true}
                                autoFocus
                              />
                            )}
                          />
                          <Controller
                            name="password"
                            control={form.control}
                            render={(
                              {
                                field: {onChange, value},
                                fieldState: {error, invalid}
                              }
                            ) => (
                              <TextField
                                id="password"
                                label={t("login:password")}
                                placeholder={t("login:password")}
                                fullWidth
                                disabled={loading}
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error?.message}
                                required={true}
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {invalid && (
                                        <IconByIntegrationType {...{ component: "span", variant: "h3", color: "error" }}
                                          iconName={"icon-alert"}
                                          returnTypography={true}
                                        />
                                      )}
                                      <ButtonBase
                                        aria-label={t("login:changePasswordVisibility")}
                                        disabled={loading}
                                        onClick={toggleShowPassword}
                                        type="button"
                                      >
                                        <IconByIntegrationType {...{ component: "span", variant: "h3" }}
                                          iconName={showPassword ? "icon-eye-slash" : "icon-eye"}
                                          returnTypography={true}
                                        />
                                      </ButtonBase>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            )}
                          />
                        </>
                      )}

                      { !customIntegrationEnabled && (
                        <Box className={classes.buttonWrapperBox}>
                          <Typography variant="body2" className={classes.textLinkSection}>
                            <span className={classes.goToResetPageText}>
                              {t("login:forgotPassword")}
                            </span>

                            <ButtonTextUnderlined
                              color="primary"
                              onClick={goToResetPage}
                            >
                              {t("login:resetPassword")}
                            </ButtonTextUnderlined>
                          </Typography>

                          <Button
                            disabled={loading}
                            variant="contained"
                            color="primary"
                            type="submit"
                            startIcon={loading && (<ButtonLoader position="prefix"/>)}
                          >
                            {t("login:logIn")}
                          </Button>
                        </Box>
                      )}
                      {customIntegrationEnabled && (
                        <Box my={1}>
                          <Button
                            disabled={loading}
                            fullWidth={true}
                            variant="contained"
                            color="secondary"
                            onClick={() => window.location.href = isMondialcareClinic ? mondialcareLoginUrl : benefitSystemLoginUrl}
                            startIcon={loading && (<ButtonLoader position="prefix"/>)}
                          >
                            {t(isMondialcareClinic ? "login:logIn" : "login:loginByBenefitSystem")}
                          </Button>
                        </Box>
                      )}
                    </form>
                    {
                      isPasswordlessEnabled && !customIntegrationEnabled && (
                        <Hidden smDown>
                          <Box className={classes.divider}/>
                          <SendAccessCodeInput passwordLessInputRef={passwordLessInputRef}/>
                        </Hidden>
                      )
                    }
                  </Box>
                  {
                    isPasswordlessEnabled && !customIntegrationEnabled && (
                      <Hidden mdUp>
                        <PasswordlessBox passwordLessInputRef={passwordLessInputRef}/>
                      </Hidden>
                    )
                  }
                  {
                    !B2BClinic && (
                      <Box my={2} mx={isSmUp ? 4 : 2}>
                        <Typography
                          className="break-spaces"
                          color="textSecondary"
                        >
                          <span className={classes.registerText1}>
                            {t("login:areYouDoctor")}
                          </span>

                          <Link
                            className={classes.registerLink}
                            href={`https://${clinicSettings?.domain}/pl/login`}
                          >
                            {t("login:goToDoctorLoginPageButton")}
                          </Link>
                        </Typography>
                      </Box>
                    )
                  }

                  {
                    clinicSettings.allowRegistration && !customIntegrationEnabled && (
                      <Box my={2} mx={isSmUp ? 4 : 2}>
                        <Typography
                          className="break-spaces"
                          color="textSecondary"
                        >
                          <span className={classes.registerText1}>
                            { t(B2BClinic ? "b2bClinic:registerLabel" : "login:registerText1") }
                          </span>

                          <Link
                            className={classes.registerLink}
                            href={
                              B2BClinic
                                ? `${registerAppUrl}${i18n.language}?clinic=${clinicId}`
                                : (isStagingApiEnv
                                  ? `https://stagingwidget.telemedi.co/consbook-widget/demo.html?visitDatePicker=mixed&apiHost=https://${clinicSettings?.domain}&fusionAuthHost=${ssoMiddlewareHost}&dashboardHost=${window.location.origin}&clinicId=${clinicSettings.frontendSettings.clinicId}`
                                  : "https://telemedi.com/pl/consultation-book/")
                            }
                          >
                            { t(B2BClinic ? "b2bClinic:registerLinkLabel" : "login:registerLinkText") }
                          </Link>

                          {
                            !B2BClinic && (
                              <span>
                                { t("login:registerText2") }
                              </span>
                            )
                          }
                        </Typography>
                      </Box>
                    )
                  }
                  <WrapperLoginAccountModal />
                </>
              </PageSection>
            )
        }
      </Container>
      <CustomIntegrationPermissionsModal />
    </>
  )
}

export default LoginPage
