import {useState} from "react"

import api from "../api/api"
import {getDependentAccounts} from "../api/routes"
import store from "../store"
import {setDependentAccounts} from "../store/user/user.slice"
import {DependentAccountType} from "../store/user/user.types"

export const useDependentAccounts = () => {
  const [isLoading,setIsLoading] = useState<boolean>(true)

  const fetchDependentAccounts = async(): Promise<null|DependentAccountType[]> => {
    try {
      const resp = await api.request({
        ...getDependentAccounts,
      })
      return await resp.data
    } catch (e) {
      console.error(e)
      return null
    }
  }
  
  const getDependentAccountsFromApi = async(
    dependentAccountsEnabled: boolean
  ): Promise<null|DependentAccountType[]> => {
    if (!dependentAccountsEnabled) {
      setIsLoading(false)
      return null
    }

    setIsLoading(true)

    const dependentAccounts = await fetchDependentAccounts()

    store.dispatch(setDependentAccounts(dependentAccounts))

    setIsLoading(false)
    
    return dependentAccounts
  }
  
  return {
    isLoading,
    getDependentAccountsFromApi,
  }
}