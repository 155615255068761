import { useCallback, useEffect, useRef } from "react"

interface useIntervalReturnType {
  start(): void;
  stop(): void;
}

const useInterval = (callback: () => void, timeout: number): useIntervalReturnType => {
  const timerRef = useRef<number | null>(null)
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  const start = useCallback(() => {
    if (timerRef.current) {
      window.clearInterval(timerRef.current)
    }
    
    timerRef.current = window.setInterval(() => {
      callbackRef.current()
    }, timeout)
  }, [timeout])

  const stop = useCallback(() => {
    if (timerRef.current) {
      window.clearInterval(timerRef.current)
      timerRef.current = null
    }
  }, [])

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        window.clearInterval(timerRef.current)
        timerRef.current = null
      }
    }
  }, [])

  return { start, stop }
}

export default useInterval
